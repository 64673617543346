import React, { useState, useEffect, useContext } from "react"
import { navigate, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import FightList from '../components/FightList'
import AddFighter from '../components/AddFighter'
import { Modal, Loading, Button, Select, Spacer, Grid } from '@geist-ui/react'
import { Edit, ChevronLeft } from '@geist-ui/react-icons'
import queryString from 'query-string'

import Instagram from '../../static/assets/Instagram.png'
import TikTok from '../../static/assets/TikTok.png'



export default function Fighter ({pageContext}) {

  const id = pageContext.id

  const [loading, setLoading] = useState(true)
  const [fighter, setFighter] = useState({})
  const [editing, setEditing] = useState()
  const [ip, setIp] = useState()

  const parsed = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {}


 useEffect(() => {
    if (id) {
      let fighterread = "fighters" + "-" + id;
      fetch(`/.netlify/functions/fauna-read/${fighterread}`).then(response => {
          return response.json().then(response => (setFighter(response.data), setLoading(false)))
          .catch(error => console.log(error))
      });
    }
  }, [id]);

 useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIp(data.ip))
      .catch(error => console.log(error))
  }, []);

 function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

function updateFighter() {
  let fighterread = "fighters" + "-" + id;
  fetch(`/.netlify/functions/fauna-read/${fighterread}`).then(response => {
      return response.json().then(response => (setFighter(response.data), setLoading(false)))
      .catch(error => console.log(error))
  });
  setEditing(false);
}

  return (
    <>
      <Helmet>
        <title>{pageContext.name} Boxer - Fanpowered</title>
        <meta name="description" content={"Vote for the Fights you want to see with " + pageContext.name + ", one of the best boxers in the world."}  />
        <script src="https://upload-widget.cloudinary.com/global/all.js" type="text/javascript"></script>
      </Helmet>
      {ip === '24.205.61.99' && <Modal width="100%" visible={editing} onClose={() => setEditing(false)}>
       <AddFighter update={fighter} id={id} onChange={() => updateFighter()} />
      </Modal>}
      {!fighter.name && <><Spacer y={2} /><Loading space={2.5} /></>}
      {fighter.name &&  <div className="fighters-page template">
        <div className="back" onClick={() => navigate('/fighters/')}>
          <ChevronLeft />All
        </div>
        {ip === '24.205.61.99' && <div className="back right" onClick={() => setEditing(true)}>
          <Edit size={20} />
        </div>}
        <img src={fighter.photo} className="fighter-photo" alt={fighter.name} />
       <h4>{fighter.name}</h4>
       {(fighter.instagram || fighter.tiktok) && <div className="flex social-icons">
         {fighter.instagram && <a href={"https://www.instagram.com/" + fighter.instagram} target="_blank"><img src={Instagram} alt="Instagram" /></a>}
         {fighter.tiktok && <a href={"https://www.tiktok.com/@" + fighter.tiktok} target="_blank"><img src={TikTok} alt="TikTok" /></a>}
       </div>}
       <div className="flex">
         <div className="chip">
          {fighter.country}
         </div>
         <div className="chip">
          {fighter.sport}
         </div>
       </div>
       <div className="fighter-stats">
        <div className="stat-item">
           <div className="stat-label">
             Age
           </div>
           <div className="stat-value">
             {getAge(fighter.birthdate)} yrs
           </div>
        </div>
        <div className="stat-item">
           <div className="stat-label">
             Height
           </div>
           <div className="stat-value">
             {Math.floor(fighter.height/12)}'{fighter.height % 12}"
           </div>
        </div>
        {fighter.reach && <div className="stat-item">
           <div className="stat-label">
             Reach
           </div>
           <div className="stat-value">
             {fighter.reach} in
           </div>
        </div>}
       </div>
       {(fighter.wins || fighter.losses || fighter.draws) && <div className="flex">
         <div className="record">
           <div className="number">
             {fighter.wins}
           </div>
           <div className="title">
             Wins
           </div>
          </div>
         <div className="record">
           <div className="number">
             {fighter.losses}
           </div>
           <div className="title">
             Losses
           </div>
          </div>
          <div className="record">
           <div className="number">
             {fighter.draws}
           </div>
           <div className="title">
             Draws
           </div>
          </div>
       </div>}
       <h6>Potential 👍 Fights</h6>
       <Spacer h={2} />
       <FightList fighter={fighter.name} id={id} short="true"
        path={"/fighter/" + pageContext.name.toLowerCase().split(" ").join("-")}
        login={parsed.login} />
      </div>}
    </>
  )
}